// export const paymentDetails = {
//     gpay:"ycfltmm72k63@idbi",
//     paytm:"ycfltmm72k63@idbi",
//     phonepe:"ycfltmm72k63@idbi",
//     upi:"ycfltmm72k63@idbi"
// }
export const paymentDetails = {
    gpay:"9643815627@okbizaxis",
    paytm:"9643815627@okbizaxis",
    phonepe:"9643815627@okbizaxis",
    upi:"9643815627@okbizaxis"
}
